













import { MutationPayload } from 'vuex';
import { defineComponent, reactive } from '@vue/composition-api';
import EventHero from '@/partials/EventHero.vue';
import { getEventById, getEventLanguage, getEventSettings } from '@/api/events';
import store from '@/store';
import Modal from '@/components/modal.vue';
import Input from '@/components/form/input.vue';
import Visitor from './Visitor.vue';
import EventPassword from './EventPassword.vue';
import useContext, { IUseContext } from '@/composition/context';
import useAuth from '@/composition/auth';
import router from '@/router';

const development =
  window.location.href.includes('beta') || window.location.href.includes('staging');

export default defineComponent({
  components: {
    EventHero,
    Visitor,
    Modal,
    Input,
    EventPassword,
  },
  setup() {
    const { translations, contextEvent } = useContext() as unknown as IUseContext;
    const { currentUser, userLoggedIn } = useAuth();

    const state = reactive({
      eventPassword: '',
      eventPasswordNeeded: false,
    });

    const checkRouteParam = () => {
      const eventId = parseInt(router.currentRoute.params.event, 10);
      if (
        contextEvent.value.settings &&
        contextEvent.value.settings.public_page_password &&
        !userLoggedIn.value
      ) {
        const eventPassword = localStorage.getItem(
          `${process.env.VUE_APP_LOCAL_STORAGE_EVENT_PASSWORD_PROP}-${contextEvent.value.id}`,
        );
        if (eventPassword !== 'password-set') {
          state.eventPasswordNeeded = true;
        }
      }

      if (!contextEvent.value || contextEvent.value.id !== eventId) {
        store.commit('setContextEvent', {});
        store.commit('setContextSession', {});

        getEventLanguage(eventId).then((response) => {
          const languages = response.data.results;

          getEventById(eventId).then((response) => {
            const eventData = response.data;
            document.title = eventData.name;
            if (!development && eventData.favicon_image && eventData.favicon_image !== '') {
              let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
              if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
              }
              link.href = eventData.favicon_image;
            }
            getEventSettings(eventId).then((response) => {
              store.commit('setContextEvent', { ...eventData, settings: response.data.results[0] });
              // Now we have the event type and setLanguage will set the taxonomy as well
              const defaultLanguage = languages.find((language) => language.default);
              if (defaultLanguage) {
                const localStorageLang = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
                const lang = localStorage.getItem(localStorageLang) as 'es' | 'pt' | 'en';
                if (lang) {
                  const userLanguageIsInEvent = languages.some((language) => language.region_code.includes(lang));
                  if (!userLanguageIsInEvent) {
                    store.commit('setLanguage', defaultLanguage.region_code);
                  } else {
                    store.commit('setLanguage', lang);
                  }
                } else {
                  store.commit('setLanguage', defaultLanguage.region_code);
                }
                const languageList = languages.map((language) => language.region_code);
                store.commit('setEventLanguages', languageList);
              }
              if (contextEvent.value.settings?.public_page_password && !userLoggedIn.value) {
                const eventPassword = localStorage.getItem(
                  `${process.env.VUE_APP_LOCAL_STORAGE_EVENT_PASSWORD_PROP}-${contextEvent.value.id}`,
                );
                if (eventPassword !== 'password-set') {
                  state.eventPasswordNeeded = true;
                }
              }
            });
          });
        });
      }
    };

    checkRouteParam();

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        const eventId = parseInt(router.currentRoute.params.event, 10);
        if (eventId) {
          getEventById(eventId).then((response) => {
            const eventData = response.data;
            document.title = eventData.name;
            store.commit('setContextEvent', { ...contextEvent.value, ...eventData });
            // Now we have the event type and setLanguage will set the taxonomy as well
          });
        }
      }
    });

    return {
      state,
      translations,
      contextEvent,
      currentUser,
      userLoggedIn,
      checkRouteParam,
      redirectUrl: process.env.VUE_APP_REDIRECT_URL,
    };
  },
  watch: {
    $route() {
      this.checkRouteParam();
    },
  },
});
