

















































// @ is an alias to /src

import { defineComponent, reactive } from '@vue/composition-api';
import Modal from '@/components/modal.vue';
import { IInput } from '@/components/form';
import Input from '@/components/form/input.vue';
import useContext from '@/composition/context';

export default defineComponent({
  components: {
    Modal,
    Input,
  },
  setup(props, { emit }) {
    const { contextEvent, translations } = useContext();

    const state = reactive({
      loading: false,
      modal: {
        isActive: true,
        isCard: true,
        close: () => {
          console.log();
        },
      },
      eventPassword: '',
      passwordInput: {
        id: 'event-password',
        name: 'event_password',
        label: 'Palavra passe',
        type: 'password',
        placeholder: 'Insira a palavra passe do evento',
        value: '',
      } as IInput,
    });

    const confirmPassword = () => {
      state.loading = true;
      setTimeout(() => {
        if (
          contextEvent.value.settings &&
          contextEvent.value.settings.public_page_password === state.eventPassword
        ) {
          state.loading = false;
          localStorage.setItem(
            `${process.env.VUE_APP_LOCAL_STORAGE_EVENT_PASSWORD_PROP}-${contextEvent.value.id}`,
            'password-set',
          );
          emit('eventPasswordSuccess');
        } else {
          state.loading = false;
          state.passwordInput = {
            ...state.passwordInput,
            message: translations.value.auth.login.wrong_password,
            error: true,
          };
        }
      }, 500);
    };
    return {
      state,
      confirmPassword,
    };
  },
});
