





































// @ is an alias to /src

import Modal from '@/components/modal.vue';
import { getNetworkingSpaces, getUserNetworking, setUserNetworking } from '@/api/chat';
import router from '@/router';
import { defineComponent, PropType } from '@vue/composition-api';
import { IModal } from '@/components';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    closeAction: {
      type: Function as PropType<() => void>,
      required: true,
    },
    successAction: {
      type: Function as PropType<(joinNetworking: boolean) => void>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {
    const { contextVisitor, translations } = useContext();
    const { currentUser } = useAuth();
    const modal: IModal = {
      isActive: true,
      isCard: true,
    };

    const event = parseInt(router.currentRoute.params.event, 10);

    return { modal, event, contextVisitor, currentUser, translations };
  },
  created() {
    this.modal = {
      isActive: true,
      isCard: true,
      onClose: this.closeModal,
      close: this.closeModal,
    };
  },

  methods: {
    closeModal() {
      this.closeAction();
      this.modal.isActive = false;
    },

    async rocketSignup() {
      this.loading = true;
      const response = await getNetworkingSpaces(this.event);
      const space = response.data[0].id;

      const responseSubscriptions = await getUserNetworking(this.contextVisitor.id);
      const networkingSubscription = responseSubscriptions.data.results.some(
        (subscription) => subscription.visitor === this.contextVisitor.id,
      );

      if (!networkingSubscription && this.contextVisitor.id) {
        await setUserNetworking({ visitor: this.contextVisitor.id, space });
      }

      this.modal.isActive = false;
      this.loading = false;
      router.push(`/event/${this.event}/networking`);

      this.successAction(false);
    },
  },
});
