













































































































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import { getVisitorById } from '@/api/visitors';
import { ISidebar, ISidebarItem } from '@/components/';
import Sidebar from '@/components/sidebar.vue';
import { IEventSettings } from '@/models/event';
import store from '@/store';
import { IVisitor } from '@/models/visitor';
import { getUserNetworking } from '@/api/chat';
import router from '@/router';
import {
  getOnlineSessionRegistrations,
  getSessionRegistrations,
  getSessions,
  onlineSessionEntranceCreate,
  onlineSessionRegistrationCreate,
  sessionEntranceCreate,
  sessionRegistrationCreate,
} from '@/api/sessions';
import { INetworking } from '@/models/networking';
import NetworkingSignup from './NetworkingSignup.vue';
import { computed, defineComponent } from '@vue/composition-api';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';

const staging = process.env.VUE_APP_SERVER_PATH !== 'https://api.beamian.com/api';

export default defineComponent({
  components: {
    Sidebar,
    NetworkingSignup,
  },

  data() {
    return {
      sidebar: {
        isActive: false,
        items: [],
      } as ISidebar,
      event: 0,
      rocketSignup: false,
      pending: false,
      networkingSubscriptions: [] as INetworking[],
      initSidebarTimeout: 0,
      navigationHidden: false,
      managingExhibitor: true,
      sessionsTimeout: 0,
    };
  },
  setup() {
    const {
      contextEvent,
      contextVisitor,
      translations,
      userManages,
      relatedVisitors,
      relatedExhibitors,
      userAdmin,
      language,
      userRolesSet,
    } = useContext();

    const { userLoggedIn, currentUser } = useAuth();

    const userIsExhibitor = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.some((exhibitor) => exhibitor.event === contextEvent.value.id),
    );
    const userExhibitor = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.find((exhibitor) => exhibitor.event === contextEvent.value.id),
    );
    const routeIsExhibitorManager = computed(() =>
      router.currentRoute.path.includes('manage-exhibitor/'),
    );

    return {
      contextEvent,
      contextVisitor,
      translations,
      userManages,
      relatedVisitors,
      relatedExhibitors,
      userAdmin,
      language,
      userRolesSet,
      userLoggedIn,
      userIsExhibitor,
      userExhibitor,
      currentUser,
      routeIsExhibitorManager,
    };
  },
  created() {
    this.event = parseInt(this.$route.params.event || (this.$route.query.event as string), 10);
    const { visitor } = router.currentRoute.query;

    this.managingExhibitor = this.$route.path.includes('manage-exhibitor/');

    if (visitor) {
      getVisitorById(parseInt(visitor as string, 10)).then((response) => {
        store.commit('setContextVisitor', response.data);
      });
    }
    this.checkRoute();

    store.commit('setContextRole', 'visitor');
    if (this.userRolesSet) {
      this.initVisitor();
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setRoles') {
        this.initVisitor();
      }
      if (
        mutation.type === 'setContextVisitor' &&
        mutation.payload.id &&
        mutation.payload.id !== undefined
      ) {
        if (this.contextEvent.settings) {
          this.initSidebar(this.contextEvent.settings);
        }
        this.initSessions();
      }
      if (mutation.type === 'setLanguage') {
        if (this.contextEvent.settings) {
          this.initSidebar(this.contextEvent.settings);
        }
        this.initSessions();
      }
      if (mutation.type === 'setContextEvent') {
        this.event = parseInt(this.$route.params.event, 10);

        if (this.contextEvent.settings) {
          this.initSidebar(this.contextEvent.settings, true);
        }
      }
      if (mutation.type === 'setUserLogin' && router.currentRoute.name !== 'ExhibitorOnboarding') {
        if (this.contextEvent.id && this.currentUser.id) {
          if (
            this.relatedVisitors &&
            !this.relatedVisitors.some((visitor) => visitor.id === this.contextVisitor.id)
          ) {
            const session = parseInt(this.$route.params.session, 10);
            const eventVisitor = this.relatedVisitors.find(
              (visitor) => visitor.event === this.event,
            );
            if (eventVisitor) {
              if (session) {
                router.push(`/event/${this.event}/session/${session}`);
              } else {
                router.push(`/event/${this.event}/`);
              }
            }
          }
        }
      }
    });
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
  methods: {
    checkRoute() {
      const { visitor } = router.currentRoute.query;
      if (visitor) {
        getVisitorById(parseInt(visitor as string, 10)).then((response) => {
          store.commit('setContextVisitor', response.data);
        });
      }
      const { currentRoute } = router;
      if (
        currentRoute.name === 'ExhibitorOnboarding' ||
        currentRoute.name === 'VisitorOnboarding' ||
        currentRoute.path.includes('manage-exhibitor')
      ) {
        this.navigationHidden = true;
      } else {
        this.navigationHidden = false;
      }
    },
    initVisitor() {
      if (this.contextEvent.settings) {
        this.initSidebar(this.contextEvent.settings, true);
      }
      const userVisitor =
        this.relatedVisitors &&
        this.relatedVisitors.find((visitor) => visitor.event === this.event);

      if (this.userLoggedIn && this.userRolesSet && userVisitor && userVisitor.id) {
        getVisitorById(userVisitor.id).then((response) => {
          const visitor = response.data;
          if (visitor.email) {
            store.commit('setContextVisitor', visitor);
          }
          if (this.contextVisitor.status !== 'registered' && this.event) {
            console.log('-- ', this.contextVisitor.status);
            this.pending = true;
            router.push(`/event/${this.event}/pending`);
          } else {
            this.pending = false;
          }
          setTimeout(() => {
            this.$gtag.event(`event_watched_${this.contextEvent.id}`, {
              event_category: `event_${this.event}`,
              event_label: `visitor_${this.contextVisitor.id}`,
            });
          }, 3000);
          setInterval(() => {
            this.$gtag.event(`event_watched_${this.contextEvent.id}`, {
              event_category: `event_${this.event}`,
              event_label: `visitor_${this.contextVisitor.id}`,
              value: 30,
            });
          }, 30000);
        });
      } else if (!this.userAdmin) {
        const guestVisitor: IVisitor = {
          id: 0,
          name: 'Guest',
          email: 'guest@beamian.com',
          event: this.event,
          status: 'registered',
        };
        store.commit('setContextVisitor', guestVisitor);
      }
    },

    initSessions() {
      clearTimeout(this.sessionsTimeout);
      const visitor = this.contextVisitor.id;
      this.sessionsTimeout = setTimeout(() => {
        const sessionsParams = {
          event: this.event,
          search: 'check-in',
        };
        getSessions(sessionsParams).then((response) => {
          const session = response.data.results[0];

          if (session) {
            if (session.external_link && session.external_link !== '') {
              store.commit('setContextSession', session);
            } else {
              store.commit('setContextSession', {});
            }
            if (visitor && visitor !== 0 && !this.userAdmin) {
              getOnlineSessionRegistrations({
                event: this.event,
                visitor,
                session: session.id,
              }).then((response) => {
                if (response.data.results.length === 0) {
                  onlineSessionRegistrationCreate({
                    attending: visitor,
                    session: session.id,
                    status: 2,
                  }).then((response) => {
                    console.log(response);
                    onlineSessionEntranceCreate({ attending: visitor, session: session.id });
                  });
                }
              });
            }
          }
        });
      }, 500);
    },

    initSidebar(eventSettings: IEventSettings, forceReload = false) {
      clearTimeout(this.initSidebarTimeout);
      this.initSidebarTimeout = setTimeout(() => {
        if (eventSettings.visitor_menu.networking.display && this.contextVisitor.id) {
          if (this.networkingSubscriptions.length > 0 && !forceReload) {
            const eventNetworking = this.networkingSubscriptions.some(
              (networking) => networking.event === this.event,
            );
            if (eventNetworking) {
              this.sidebar = this.getNavigation(eventSettings, false);
              this.$forceUpdate();
              this.defaultRedirect(eventSettings, false);
            } else {
              this.sidebar = this.getNavigation(eventSettings, true);
              this.defaultRedirect(eventSettings, true);
            }
          } else {
            getUserNetworking(this.contextVisitor.id).then((response) => {
              this.networkingSubscriptions = response.data.results;

              const eventNetworking = this.networkingSubscriptions.some(
                (networking) => networking.event === this.event,
              );
              if (eventNetworking) {
                this.sidebar = this.getNavigation(eventSettings, false);
                this.defaultRedirect(eventSettings, true);
              } else {
                this.sidebar = this.getNavigation(eventSettings, true);
                this.defaultRedirect(eventSettings, false);
              }
            });
          }
        } else {
          this.sidebar = this.getNavigation(eventSettings, true);
          this.defaultRedirect(eventSettings, false);
        }
      }, 500);
    },

    updateSidebar(joinNetworking: boolean) {
      if (this.contextEvent.settings) {
        this.sidebar = this.getNavigation(this.contextEvent.settings, joinNetworking);
      } else {
        const unsubscribe = store.subscribe((mutation: MutationPayload) => {
          if (mutation.type === 'setContextEvent') {
            if (this.contextEvent.settings) {
              this.sidebar = this.getNavigation(this.contextEvent.settings, joinNetworking);
            }
          }
          unsubscribe();
        });
      }
    },

    eventHasValidDates() {
      const currentDate = new Date();
      const eventStartDate = new Date(this.contextEvent.start_date);
      const eventEndDate = new Date(this.contextEvent.end_date);

      const eventIsFinished = currentDate >= eventEndDate;
      const eventStarted = eventStartDate <= currentDate;
      return staging || this.userAdmin || (eventStarted && !eventIsFinished);
    },

    closeRocketModal() {
      this.rocketSignup = false;
    },

    openRocketSignup() {
      this.rocketSignup = true;
    },

    defaultRedirect(eventSettings: IEventSettings, joinNetworking: boolean) {
      if (router.currentRoute.name === 'EventHome') {
        if (eventSettings.visitor_first_page === 'calendar') {
          router.push(`/event/${this.event}/sessions`);
        }
        if (eventSettings.visitor_first_page === 'exhibitors') {
          router.push(`/event/${this.event}/exhibitors`);
        }
        if (eventSettings.visitor_first_page === 'networking') {
          if (joinNetworking) {
            this.openRocketSignup();
          } else {
            router.push(`/event/${this.event}/networking`);
          }
        }
      }
    },

    getNavigation(eventSettings: IEventSettings, joinNetworking: boolean) {
      const eventHasValidDates = this.eventHasValidDates();

      const homeHidden = !eventSettings.visitor_menu.home.display;
      const exhibitorsHidden = !eventSettings.visitor_menu.exhibitors.display;
      const productsHidden = !eventSettings.visitor_menu.products.display;
      const sessionsHidden = !eventSettings.visitor_menu.calendar.display;
      const networkingHidden =
        !eventSettings.visitor_menu.networking.display || this.contextVisitor.id === 0;

      const home: ISidebarItem = {
        route: `/event/${this.event}/`,
        label: this.translations.visitors.sidebar.home,
        icon: 'home',
        hidden: homeHidden,
        order: eventSettings.visitor_menu.home.order,
      };

      const exhibitors: ISidebarItem = {
        route: eventHasValidDates ? `/event/${this.event}/exhibitors` : undefined,
        label: this.translations.visitors.sidebar.exhibitors,
        icon: 'storefront',
        hidden: exhibitorsHidden,
        order: eventSettings.visitor_menu.exhibitors.order,
      };

      const sessions: ISidebarItem = {
        label: this.translations.visitors.sidebar.sessions,
        icon: 'event',
        hidden: sessionsHidden,
        order: eventSettings.visitor_menu.calendar.order,
        route: eventHasValidDates ? `/event/${this.event}/sessions` : undefined,
      };

      const products: ISidebarItem = {
        label: this.translations.exhibitors.sidebar.products,
        icon:
          this.contextEvent.settings && this.contextEvent.settings.event_type === 'jf'
            ? 'inventory_'
            : 'local_offer',
        order: eventSettings.visitor_menu.products.order,
        hidden: productsHidden,
        route: eventHasValidDates ? `/event/${this.event}/products` : undefined,
      };

      const networking: ISidebarItem = {
        label: 'Networking',
        icon: 'group',
        order: eventSettings.visitor_menu.networking.order,
        hidden: networkingHidden,
      };

      if (joinNetworking && eventHasValidDates) {
        networking.action = this.openRocketSignup;
      } else {
        networking.route = eventHasValidDates ? `/event/${this.event}/networking` : undefined;
      }

      const items = [home, exhibitors, sessions, networking, products].sort((prev, next) => {
        if (next.order !== undefined && prev.order !== undefined) {
          if (prev.order < next.order) {
            return -1;
          }
          return 1;
        }
        return 0;
      });

      return {
        title: this.translations.visitors.sidebar.title,
        isActive: false,
        items,
      };
    },
  },
});
